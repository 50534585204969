import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  ConversionTrackerStorage,
  conversionTrackerStorageKey,
} from "../hooks/use-conversion-tracker";
import { useLocalStorage } from "../hooks/use-local-storage";
import { Product } from "../pages/api/plans";
import { promotionCodes } from "../pages/billing";
import { charactersToHours } from "../utils/characters-to-hours";
import { ButtonCallbackProps, PricingCard } from "./pricing-card";

interface Props {
  promotionCodeId?: string;
  products: Product[];
  buttonCallback: (props: ButtonCallbackProps) => void;
}

export default function PricingSwitch(props: Props) {
  const { storageData } = useLocalStorage<ConversionTrackerStorage>(
    conversionTrackerStorageKey
  );

  const [discountPercentage, setDiscountPercentage] = useState<number>();

  const isPoland = storageData?.referrer?.includes("talkio.pl");
  const [billingTypeChecked, setBillingTypeChecked] = useState(false);

  // useEffect(() => {
  //   if (props.promotionCodeId) {
  //     const percentage = promotionCodes.find(
  //       (p) => p.code === props.promotionCodeId
  //     )?.percentage;

  //     if (typeof percentage === "number") {
  //       setDiscountPercentage(percentage);
  //     }
  //   }
  // }, [props.promotionCodeId]);

  useEffect(() => {
    if (isPoland) {
      setBillingTypeChecked(true);
    }
  }, [isPoland]);

  const products = props.products.filter((p) => {
    const monthly =
      p.price.type === "recurring" && p.price.recurring?.interval === "month";
    const yearly =
      p.price.type === "recurring" && p.price.recurring?.interval === "year";
    const oneTime = p.price.type === "one_time";

    return monthly || yearly || oneTime;
  });

  const displayPrice = (
    amount: number,
    currency: string,
    priceType: string,
    interval?: string
  ) => {
    let curLabel = currency.toUpperCase();
    if (currency === "usd") {
      curLabel = "$";
    } else if (currency === "eur") {
      curLabel = "€";
    }

    const divider = priceType === "one_time" ? 6 : interval === "year" ? 12 : 1;

    return `${curLabel}${amount / divider / 100}`;
  };

  // const subscriptionFeatures = [];

  // const onetimePaymentFeatures = [
  //   "All features included",
  //   "7 day money back guarantee",
  //   "Two months free compared to subscription",
  // ];

  const subscriptionPaymentProviders = ["Visa", "MasterCard", "PayPal"];

  const onetimePaymentProviders = [
    "Visa",
    "MasterCard",
    "PayPal",
    "WeChat",
    "Przelewy24",
    "Giropay",
  ];

  return (
    <Box>
      {/* <Flex alignItems={"center"} justifyContent="center" mb={5}>
        <Text w="170px" textAlign="right" fontSize="sm">
          Pay monthly
        </Text>
        <Switch
          colorScheme="brand.secondary"
          size="md"
          mx={3}
          isChecked={billingTypeChecked}
          onChange={() => setBillingTypeChecked(!billingTypeChecked)}
        />
        <Text fontSize="sm" w="170px">
          Pay once{" "}
          <Box as="span" color="brand.green.500">
            (save 30%)
          </Box>
        </Text>
      </Flex> */}
      <Flex
        justifyContent="center"
        alignItems={"center"}
        flexDir={{ base: "column", md: "row" }}
      >
        {products.map((p, i) => (
          <PricingCard
            key={i}
            title={p.name}
            description={
              p.price.type === "one_time"
                ? "per month - billed once"
                : p.price.recurring?.interval === "year"
                ? "per month - billed yearly"
                : "per month"
            }
            price={displayPrice(
              p.price.unit_amount,
              p.price.currency,
              p.price.type,
              p.price.recurring?.interval
            )}
            discountPrice={
              typeof discountPercentage === "number"
                ? displayPrice(
                    (p.price.unit_amount * (100 - discountPercentage)) / 100,
                    p.price.currency,
                    p.price.type,
                    p.price.recurring?.interval
                  )
                : undefined
            }
            discountPercentage={discountPercentage}
            submitLink={p.checkoutLink}
            buttonCallback={props.buttonCallback}
            productId={p.id}
            submitText={
              p.price.type === "one_time"
                ? "Buy Now with 7-Day Money Back Guarantee"
                : "Start Your Free Trial"
            }
            features={[
              p.price.type === "one_time"
                ? "7-day full refund - cancel anytime"
                : "7-day free trial - cancel anytime",

              "Supports 40+ languages",
              "Life-like voice conversations",
              "Instant feedback",
              "Pronunciation practice",
              "Interactive wordbook",
              "Multilingual speech recognition",
              "Role-playing, topics, quizzes & more!",
            ]}
            colorScheme={p.price.type === "one_time" ? "white" : "white"}
            paymentProviders={
              p.price.type === "one_time"
                ? onetimePaymentProviders
                : subscriptionPaymentProviders
            }
          />
        ))}
      </Flex>
    </Box>
  );
}
